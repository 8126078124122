<template>
  <b-container>
    <b-row class="flex-column min-vh-100" align-v="center" align-h="center">
      <div class="logo">
        <huddle-logo></huddle-logo>
      </div>
      <form class="w-100" @submit="signIn">
        <b-row
          class="flex-column"
          no-gutters
          align-h="center"
          align-content="center"
        >
          <h1 class="text-center">Welcome Back</h1>
          <p class="form-description">
            Enter your credentials to access your account.
          </p>
          <b-input-group class="icon-prepend">
            <b-input-group-prepend>
              <span class="input-group-text">
                <b-icon-person-fill></b-icon-person-fill>
              </span>
            </b-input-group-prepend>
            <b-form-input
              v-model="username"
              placeholder="Enter your username"
              required
            >
            </b-form-input>
          </b-input-group>

          <b-input-group class="icon-prepend">
            <b-input-group-prepend>
              <span class="input-group-text">
                <b-icon-lock-fill></b-icon-lock-fill>
              </span>
            </b-input-group-prepend>
            <b-form-input
              placeholder="Enter your password"
              required
              v-model="password"
              type="password"
            >
            </b-form-input>
          </b-input-group>

          <b-button :disabled="loading" type="submit" variant="success">
            Sign in
          </b-button>
        </b-row>
      </form>
      <router-link to="/forgot-password">
        <b-button class="forgot-password text-decoration-none" variant="link">
          <span>Forgot your password?</span> <strong>Reset Password</strong>
        </b-button>
      </router-link>
    </b-row>
  </b-container>
</template>

<script>
import HuddleLogo from "@/components/ui/huddle-logo";
import vue from "vue";
import AuthService from "@/api/auth";

export default vue.extend({
  name: "SignIn",
  components: { HuddleLogo },
  data: () => {
    return {
      username: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    async signIn(event) {
      event.preventDefault();
      if (this.password.length > 0) {
        this.loading = true;
        try {
          const result = await AuthService.signIn(this.username, this.password);
          localStorage.setItem("token", result.data.token);
          this.$emit("loggedIn");
          if (this.$route.params.nextUrl != null) {
            await this.$router.push(this.$route.params.nextUrl);
          } else {
            await this.$router.push("/");
          }
        } catch (e) {
          this.loading = false;
        }
      }
    },
  },
});
</script>

<style scoped lang="scss">
.logo {
  margin-bottom: 3.875rem;
}

.sign-in {
  height: 100vh;
}

form {
  max-width: 35.625rem;
  padding: 3.125rem 3.75rem 3.75rem;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 57, 109, 0.03);
  border-radius: 10px;

  h1 {
    font-size: 1.625rem;
    font-weight: 500;
    color: var(--primary);
    line-height: 1.625rem;
  }

  p.form-description {
    font-weight: 300;
    font-size: 1rem;
    opacity: 0.5;
    line-height: 1.625rem;
    text-align: center;
    color: var(--primary);
    margin: 1rem 1rem 3.125rem;
  }

  .input-group {
    margin-bottom: 1.875rem;

    .form-control::placeholder {
      opacity: 0.5;
    }
  }

  .btn-success {
    background-color: var(--primary);
    color: var(--white);
    line-height: 1.625rem;
  }
}

.forgot-password {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.625rem;
  margin-top: 3.625rem;
  color: rgba(0, 147, 72, 0.35);

  strong {
    color: rgba(0, 147, 72, 1);
    font-weight: inherit;
  }

  &:focus {
    box-shadow: none;
  }

  &:hover,
  &:focus,
  &:active {
    strong {
      text-decoration: underline;
    }
  }
}
</style>
